import React, { Fragment, useEffect, useState } from "react";
import { CustomPinInputField } from "../../CustomHTMLElements/CustomPinInputField.component";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import { selectUserWallets } from "../../../redux/selectors/userSelector";
import { ITopUpInfo } from "../PortfolioModal.component";
import moment from "moment";
import { postData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { message } from "antd";
import { errorHandler } from "../../../helpers/errorHandler";
import { appInsights } from "../../AppInsight/AppInsight";

interface IProps {
  topUpInfo: ITopUpInfo;
  handleCancel: () => void;
}

const TransactionPinModal = ({ topUpInfo, handleCancel }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState<string | null>(null);
  const [isValidPin, setIsValidPin] = useState(false);

  useEffect(() => {
    appInsights.trackPageView({
      name: "TransactionPinModal.tsx",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    if (pin?.length === 4) {
      setIsValidPin(true);
    } else {
      setIsValidPin(false);
    }
  }, [pin]);

  const onSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    const reqBody = { ...topUpInfo };
    reqBody.pin = String(pin);
    reqBody.transaction_date = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
    console.log({
      reqBody,
    });
    try {
      await postData(apiEndpoints.INVESTMENT_TOP_UP, reqBody);
      message.success(
        "Investment top-up request is processing.",
        undefined,
        () => handleCancel()
      );
    } catch (error) {
      message.error(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: "TransactionPinModal.tsx" },
      });
    } finally {
      setLoading(false);
      setPin(null);
    }
  };

  return (
    <div className="d-flex text-center justify-content-center align-items-center flex-column py-5 ">
      <div className="w-100">
        <form onSubmit={onSubmit}>
          <CustomPinInputField
            setValue={(value: string) => setPin(value)}
            length={4}
            label="Enter transaction PIN to proceed"
            focus={true}
          />

          <div className="d-flex justify-content-between mt-5">
            <button
              type="submit"
              className="btn btn-sm advancly-btn btn-block"
              disabled={loading || !isValidPin}
            >
              {loading ? (
                <span className="spinner-border text-white" />
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TransactionPinModal;
