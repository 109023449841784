import React, { useEffect, useState } from "react";
import { match, useRouteMatch } from "react-router-dom";
import { Divider, Modal } from "antd";
import PaymentMethodModal from "./PaymentMethodModal";
import FundingAmountModal from "./FundingAmountModal";
import TransactionPinModal from "./TransactionPinModal";
import { LeftOutlined } from "@ant-design/icons";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { selectUserWallets } from "../../redux/selectors/userSelector";
import { appInsights } from "../AppInsight/AppInsight";
import BankTransferModal from "./BankTransferModal";

interface IProps {
  handleCancel: () => void;
  isModalOpen: boolean;
  currency: "NGN" | "KES" | "USD";
}

export interface ITopUpInfo {
  amount: number | undefined;
  funder_investment_id: number | undefined;
  transaction_date: string;
  base64_file_string: string;
  filename_with_extension: string;
  topup_type: number;
  wallet_id: string;
  pin: string;
}

export enum PortfolioFundingSteps {
  PaymentMethod = "PaymentMethod",
  BankTransfer = "BankTransfer",
  UploadReceipt = "UploadReceipt",
  TransactionSuccess = "TransactionSuccess",
  FundingAmount = "FundingAmount",
  TransactionPin = "TransactionPin",
}

const PortfolioModal = ({ isModalOpen, handleCancel, currency }: IProps) => {
  const match: match<{ id: string }> = useRouteMatch();
  const investmentId = Number(match.params.id);
  const { lcy_wallet_id, usd_wallet_id } = useTypedSelector(selectUserWallets);
  const walletId = currency === "USD" ? usd_wallet_id : lcy_wallet_id;
  const defaultTopUpInfo = {
    amount: undefined,
    base64_file_string: "",
    pin: "",
    topup_type: 1,
    filename_with_extension: "",
    transaction_date: "",
    funder_investment_id: investmentId,
    wallet_id: walletId,
  };
  const [topUpInfo, setTopUpInfo] = useState<ITopUpInfo>(defaultTopUpInfo);
  const [type, setType] = useState<"wallet" | "bankTransfer" | undefined>();
  const [currentStep, setCurrentStep] = useState(
    PortfolioFundingSteps.FundingAmount
  );

  const handleResetState = () => {
    setTopUpInfo(defaultTopUpInfo);
    setCurrentStep(PortfolioFundingSteps.FundingAmount);
    handleCancel();
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "PortfolioModal.component.tsx",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    if (type === "wallet") {
      setCurrentStep(PortfolioFundingSteps.TransactionPin);
    }
    if (type === "bankTransfer") {
      setCurrentStep(PortfolioFundingSteps.BankTransfer);
    }
  }, [type]);

  const handleCurrentScreen = () => {
    switch (currentStep) {
      case PortfolioFundingSteps.FundingAmount:
        return (
          <FundingAmountModal
            topUpInfo={topUpInfo}
            setTopUpInfo={setTopUpInfo}
            setCurrentStep={setCurrentStep}
          />
        );
      case PortfolioFundingSteps.PaymentMethod:
        return <PaymentMethodModal type={type} setType={setType} />;
      case PortfolioFundingSteps.TransactionPin:
        return (
          <TransactionPinModal
            topUpInfo={topUpInfo}
            handleCancel={handleResetState}
          />
        );
      case PortfolioFundingSteps.BankTransfer:
        return (
          <BankTransferModal
            currency={currency}
            topUpInfo={topUpInfo}
            setTopUpInfo={setTopUpInfo}
            setCurrentStep={setCurrentStep}
          />
        );
    }
  };

  const handleModalTitle = () => {
    switch (currentStep) {
      case PortfolioFundingSteps.FundingAmount:
        return (
          <div className="d-flex align-items-center">
            <p className="p-none">Amount to Fund</p>
          </div>
        );
      case PortfolioFundingSteps.PaymentMethod:
        return (
          <div className="d-flex align-items-center">
            <LeftOutlined
              rev
              className="mr-3 cursor-pointer"
              onClick={() =>
                setCurrentStep(PortfolioFundingSteps.FundingAmount)
              }
            />
            <p className="p-none">Payment Method</p>
          </div>
        );
      case PortfolioFundingSteps.TransactionPin:
        return (
          <div className="d-flex align-items-center">
            <LeftOutlined
              rev
              className="mr-3 cursor-pointer"
              onClick={() => {
                if (type === "wallet") {
                  setCurrentStep(PortfolioFundingSteps.PaymentMethod);
                } else {
                  setCurrentStep(PortfolioFundingSteps.BankTransfer);
                }
              }}
            />
            <p className="p-none">Transaction Pin</p>
          </div>
        );
      case PortfolioFundingSteps.BankTransfer:
        return (
          <div className="d-flex align-items-center">
            <LeftOutlined
              rev
              className="mr-3 cursor-pointer"
              onClick={() => {
                setCurrentStep(PortfolioFundingSteps.PaymentMethod);
              }}
            />
            <p className="p-none">Bank Transfer</p>
          </div>
        );
    }
  };

  useEffect(() => {
    if (currentStep === PortfolioFundingSteps.PaymentMethod) {
      setType(undefined);
    }
  }, [currentStep]);

  return (
    <Modal
      title={handleModalTitle()}
      open={isModalOpen}
      onCancel={() => {
        handleResetState();
      }}
      footer={null}
    >
      <Divider />
      {handleCurrentScreen()}
    </Modal>
  );
};

export default PortfolioModal;
