import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

type paramProps = {
  funderInvestmentId: number;
};

const getAnInvestment = async ({ queryKey }: any) => {
  const { funderInvestmentId } = queryKey[1];
  const { data } = await getData(
    `${apiEndpoints.AVAILABLE_INVESTMENTS}/${funderInvestmentId}`
  );
  return data;
};

const getAnInvestmentTransactionsAndDetails = async ({ queryKey }: any) => {
  const { funderInvestmentId } = queryKey[1];
  const { data } = await getData(
    `${apiEndpoints.INVESTMENT}/${funderInvestmentId}/transactions`
  );
  return data;
};

export default function useInvestment({ funderInvestmentId }: paramProps) {
  return useQuery(
    ["getAnInvestment", { funderInvestmentId }],
    getAnInvestment,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: typeof funderInvestmentId !== "undefined",
    }
  );
}

export function useInvestmentTransactions({ funderInvestmentId }: paramProps) {
  return useQuery(
    ["getAnInvestmentTransactionsAndDetails", { funderInvestmentId }],
    getAnInvestmentTransactionsAndDetails,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
}
