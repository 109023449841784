import { useState, useRef, Dispatch, SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../helpers/yupValidators";
import { postData } from "./../../apis/apiMethods";
import { errorHandler } from "./../../helpers/errorHandler";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CustomInputField from "./../CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "./../CustomHTMLElements/CustomSelectDropdown";
import useBanks from "./../../hooks/custom-hooks/useBanks";
import PageLoader from "./../PageLoader/PageLoader.component";
import Loader from "../Loader/Loader.component";
import { reValidateUserActivationStatus } from "../../helpers/isUserActivated";
import { useTypedSelector } from "./../../hooks/redux-hooks/useTypedSelector";
import { selectUser } from "./../../redux/selectors/userSelector";
import { useActions } from "./../../hooks/redux-hooks/useActions";
import { useQueryClient } from "react-query";
import useValidateBankAccount from "./../../hooks/custom-hooks/useValidateBankAccount";
import { appInsights } from "../AppInsight/AppInsight";
import { Button, Divider, Form, Input, Modal, Select } from "antd";
import { IBankAccount } from "../../interfaces/bankAccounts";

interface IProps {
  setError: Dispatch<SetStateAction<string>>;
  setSuccess: Dispatch<SetStateAction<string>>;
  handleCancel: () => void;
  isModalOpen: boolean;
  bankAccount: IBankAccount | undefined;
  otp: string;
}

const NigeriaBankAccountUpdate: React.FC<IProps> = ({
  setSuccess,
  setError,
  handleCancel,
  isModalOpen,
  bankAccount,
  otp,
}) => {
  const [form] = Form.useForm();
  const user = useTypedSelector(selectUser);
  const { setCurrentUser } = useActions();
  const [loading, setLoading] = useState(false);
  const [inputAccountNumber, setInputAccountNumber] = useState("");
  const [bankCode, setBankCode] = useState("");
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const dismissModal = () => {
    dismissRef?.current?.click();
  };

  const queryClient = useQueryClient();

  const { data, isError, isLoading } = useBanks("NG");

  useEffect(() => {
    // Update form fields when bankAccount prop changes
    if (isModalOpen && bankAccount) {
      form.setFieldsValue({
        bank_id: data?.data?.find(
          (bank: any) => bank.code === bankAccount?.bank_code
        )?.name,
        bank_name: bankAccount.bank_name,
        bank_account_number: bankAccount?.account_number,
        bank_account_name: bankAccount.account_name,
      });
      setBankCode(
        data?.data?.find((bank: any) => {
          return bank?.name === bankAccount.bank_name;
        })?.code
      );
      setInputAccountNumber(bankAccount?.account_number);
    } else {
      form.resetFields(); // Reset the form if bankAccount becomes undefined or null
    }
  }, [bankAccount, isModalOpen, data]);

  const {
    data: accountName,
    isLoading: isAccountNameLoading,
    isError: isAccountNameError,
    error: accountNameError,
  } = useValidateBankAccount({
    bankCode: bankCode,
    accountNumber: inputAccountNumber,
  });

  useEffect(() => {
    console.log({
      accountName,
    });
    if (accountName) {
      form.setFieldValue("bank_account_name", accountName);
    }
  }, [accountName]);

  if (isAccountNameError) {
    setError(errorHandler(accountNameError));
  }
  const handleReset = () => {
    form.resetFields();
  };

  const onSubmit = async ({
    bank_id,
    bank_account_number,
  }: {
    bank_id: string;
    bank_account_number: string;
  }) => {
    setLoading(true);
    setError("");
    setSuccess("");

    const reqBody = {
      bank_name:
        data?.data.find((b: { code: string }) => b.code === bankCode).name ||
        "",
      bank_code: String(bankCode),
      account_number: bank_account_number,
      account_name: accountName,
      currency: "NGN",
      funder_id: user?.funder_id,
      swift_code: "",
      routing_number: "",
      is_update: !!bankAccount,
      otp,
    };

    try {
      await postData(
        `${apiEndpoints.CUSTOMER_BANK_ACCOUNTS}/${user?.funder_id}/bank_accounts`,
        reqBody
      );
      queryClient.invalidateQueries("getFunder");
      // Revalidate User Status if he is initally not activated
      if (!user.activated) {
        const activated = await reValidateUserActivationStatus();
        setCurrentUser({ ...user, activated });
      }
      handleReset();
      handleCancel();
      setSuccess("Bank information updated successfully.");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: NigeriaBankAccountUpdate },
      });
    }
  };

  useEffect(() => {
    console.log({
      loading,
      isLoading,
      isError,
      isAccountNameLoading,
      accountName,
      isAccountNameError,
    });
  }, [
    loading,
    isLoading,
    isError,
    isAccountNameLoading,
    accountName,
    isAccountNameError,
  ]);

  return (
    <Modal
      title={`Add your NGN bank information`}
      open={isModalOpen}
      onCancel={() => {
        handleReset();
        handleCancel();
      }}
      footer={null}
    >
      <Divider />
      {isLoading || isAccountNameLoading ? (
        <PageLoader />
      ) : (
        <Form form={form} onFinish={onSubmit} layout="vertical" size="large">
          <Form.Item
            name="bank_name"
            label="Bank"
            rules={[{ required: true, message: "Please select your bank!" }]}
          >
            <Select
              placeholder="Choose Bank"
              onChange={(value) => {
                console.log({
                  value,
                });
                setBankCode(
                  data?.data?.find((bank: any) => {
                    return bank?.name === form.getFieldValue("bank_name");
                  })?.code
                );
                setInputAccountNumber("");
                form.setFieldValue("bank_account_number", "");
              }}
            >
              {data?.data?.map(({ name, id }: { name: string; id: number }) => (
                <Select.Option value={name} key={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="bank_account_number"
            label="Account Number"
            rules={[
              { required: true, message: "Please enter account number!" },
            ]}
          >
            <Input
              type="number"
              maxLength={10}
              placeholder="Enter Account number"
              onChange={(e) => setInputAccountNumber(e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Account Name" name="bank_account_name">
            <Input
              type="text"
              maxLength={128}
              placeholder="Account Name"
              readOnly
            />
          </Form.Item>

          <Button
            className="advancly-btn mt-3 w-100"
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={
              loading ||
              isLoading ||
              isError ||
              isAccountNameLoading ||
              !accountName ||
              isAccountNameError
            }
          >
            Update Bank Information
          </Button>
        </Form>
      )}
    </Modal>
  );
};
export default NigeriaBankAccountUpdate;
