export enum apiEndpoints {
  SIGN_UP = "/account/signup_funder",
  VALIDATE_SIGN_UP_EMAIL = "/account/verify",
  VALIDATE_SIGN_UP_OTP = "/account/otp/verify",
  RESEND_SIGN_UP_OTP = "/account/otp/generate",
  LOGIN = "/account/custom_login",
  FORGOT_PASSWORD = "/account/forgot-password",
  CHANGE_PASSWORD = "/account/change_password",
  RESET_PASSWORD = "/account/reset_password",
  CONTACT_US = "/misc/contact_form",
  PROFILE = "/account/profile",
  USERS = "/account/funder_user",
  CREATE_FUNDER_USER = "/account/create_funder_user",
  USER = "/account/funder_details",
  USER_STATUS = "/account/funder_signup_status",
  UPDATE_USER = "/account/update_funder",
  UPDATE_USER_BUSINESS_INFO = "/account/add_funder_business_info",
  UPDATE_USER_KYC = "/account/save_funder_kyc",
  UPDATE_USER_BANK_INFO = "/account/add_funder_bank",
  BUSINESS_CATEGORIES = "/misc/list_category/",
  BUSINESS_SECTORS = "/misc/sectors",
  COUNTRY_CODES = "/account/all_country/",
  // WORLD_COUNTRY_CODES = "/account/world_countries",
  WORLD_COUNTRY_CODES = "/account/advancly_specific_countries",
  COUNTRY_STATES = "/account/country_states",
  ALL_BANKS = "/account/signed_banks_country",
  ALL_BANKS_BY_COUNTRY = "/account/signed_banks_country",
  AVAILABLE_INVESTMENTS = "/eco/investment_product",
  INVESTMENT_TERM_SHEET = "/eco/investment_product/rate",
  WALLET_BALANCE = "/account/get_funder_wallet_balance",
  WALLET_TRANSACTIONS = "/account/get_funder_wallet_transactions",
  SAVE_SECURITY_QUESTION = `/account/security_question/answers`,
  MAKE_INVESTMENT = "/account/create_funder_investment",
  CONFIRM_SECURITY_QUESTION = "/account/security_question",
  GET_SAVED_SECURITY_QUESTION = "/account/security_question",
  GET_ALL_SECURITY_QUESTIONS = "/account/security_question/all",
  SAVE_SECURITY_PIN = "/account/pin/create",
  MAKE_WITHDRAWAL = "/account/withdraw_funds",
  PORTFOLIOS = "/account/get_funder_investments",
  PORTFOLIO = "/account/get_funder_investment_by_id",
  DOWNLOAD_INVESTMENT_SLIP = "/account/investment_slip",
  DOWNLOAD_TERM_SHEET = "/account/termsheet",
  FUND_WALLET = "/account/topup_wallet",
  GET_WALLET_ACCOUNT = "/wallet/accounts",
  INITIATE_PAYSTACK_PAYMENT = "/account/log_paystack_request",
  DASHBOARD_STATS = "/statistics/funder",
  POWERBI_EMBED = "/misc/powerbi/embedtoken",
  VALIDATE_BANK_ACCOUNT = "/account/validate_bank_account",
  GET_INTEREST_CALCULATION = "/account/calculate_investment_interest_mifos",
  FUNDER_ADDRESS_INFORMATION = "/account/funder_address",
  FUNDER_UPLOADED_KYC_DOCUMENTS = "/account/view_funder_kyc_documents",
  FUNDER_KYC_DOCUMENTS_UPLOAD = "/account/funder_kyc_upload",
  NEXT_OF_KIN = "/account/view_customer_nextofkin",
  UPDATE_NEXT_OF_KIN = "/account/save_customer_nextofkin",
  GET_LIST_OF_DOCUMENTS_TO_UPLOAD = "/document/investor_document_types",
  GET_GENDER_OPTIONS = "/account/get_genders",
  BVN_VALIDATION = "/account/run_bvn_validation",
  RESOLVE_BVN = "/misc/verify_kyc",
  GENERAL_BROADCAST_HUB = "/inapppush/broadcast",
  SPECIFIC_BROADCAST_HUB = "/inapppush/specific",
  NOTIFICATION_LIST = "notification/user_message/list",
  SET_NOTIFICATION_AS_READ = "notification/user_message_set_read",
  INVESTMENT_BANK_ACCOUNTS = "/collectionaccount/get_default",
  WITHDRAW_INVESTMENT = "/account/close_withdraw_funder_investment",
  ROLL_OVER_INVESTMENT = "/account/rollover_funder_investment",
  GET_MINIMUM_TOP_UP_AMOUNTS = "/currencies",
  CURRENCIES = "/account/funder_investment_currencies",
  CUSTOMER_BANK_ACCOUNTS = "/customer",
  OTP_TYPES = "/enum/otp_use_types",
  INVESTMENT_TOP_UP = "/eco/investment/top_up",
  INVESTMENT = "/eco/investment",
}
