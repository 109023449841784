import React, { useEffect, useRef, useState } from "react";
import { appInsights } from "../../AppInsight/AppInsight";
import { TInvestmentAccounts } from "../../../interfaces/investmentAccounts";
import { getData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import { message } from "antd";
import Loader from "../../Loader/Loader.component";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import * as yup from "yup";
import { yupValidators } from "../../../helpers/yupValidators";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { convertToBase64 } from "../../../helpers/base64";
import { ITopUpInfo, PortfolioFundingSteps } from "../PortfolioModal.component";

const schema = yup.object().shape({
  transactionReceipt: yupValidators.genericRequired({
    message: "Upload transaction receipt",
  }),
});

interface IProps {
  currency: string;
  topUpInfo: ITopUpInfo;
  setTopUpInfo: (info: ITopUpInfo) => void;
  setCurrentStep: (step: PortfolioFundingSteps) => void;
}

const BankTransferModal = ({
  currency,
  setTopUpInfo,
  topUpInfo,
  setCurrentStep,
}: IProps) => {
  const fileInputRef = useRef<null | HTMLInputElement>(null);
  const [copied, setCopied] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState<TInvestmentAccounts>({
    id: 0,
    accountName: "",
    accountNumber: "",
    bankCode: "",
    bankName: "",
    currency: "",
    isDefault: false,
    type: "",
    routingNumber: "",
    swiftCode: "",
    updatedAt: "",
    createdAt: "",
  });

  useEffect(() => {
    fetchInvestmentAccounts();
  }, [currency]);

  const copyText = (text: string) => {
    setCopied("Copied.");
    navigator.clipboard.writeText(text);
    setTimeout(() => setCopied(""), 3000);
  };

  const fetchInvestmentAccounts = async () => {
    try {
      setIsLoading(true);
      const response = await getData(
        `${apiEndpoints.INVESTMENT_BANK_ACCOUNTS}?type=2&currency=${currency}`
      );
      setBankDetails(response.data);
    } catch (error) {
      message.error(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: "OfflineInvestmentBankDetails" },
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "BankTransferModal.tsx",
      isLoggedIn: true,
    });
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError: setFieldError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onChangeReceipt = async (e: any) => {
    if (!e.target.files[0]) return;
    const fileType = e.target.files[0].type;
    const isValidFile =
      fileType.startsWith("image/png") ||
      fileType.startsWith("image/jpg") ||
      fileType.startsWith("image/jpeg") ||
      fileType.startsWith("application/pdf");

    if (!isValidFile) {
      return message.error("Invalid File type.");
    }
    const fileBase64: string = await convertToBase64(e.target.files[0]);
    setValue("transactionReceipt", e.target.files[0].name);
    setTopUpInfo({
      ...topUpInfo,
      base64_file_string: fileBase64?.split("base64,")[1],
      filename_with_extension: e.target.files[0].name,
    });
  };

  const onSubmit = () => {
    setCurrentStep(PortfolioFundingSteps.TransactionPin);
  };

  return (
    <div>
      <p>
        Kindly transfer your top up amount to this account, click continue when
        it is done
      </p>
      {isLoading ? (
        <div className="d-flex justify-content-center my-5">
          <Loader type="lg" />
        </div>
      ) : (
        <div className="bg-lighter-grey p-3">
          <div className="mb-4">
            <div className="form-label mb-2">
              <small>Account Name</small>
            </div>
            <h6 className="page-subtitle">{bankDetails?.accountName}</h6>
          </div>
          <div className="mb-4">
            <div className="form-label mb-2">
              <small>Account Number</small>
            </div>
            <h6 className="page-subtitle">
              {bankDetails?.accountNumber}
              <span
                className="font-weight-bold cursor-pointer ml-4"
                onClick={() => copyText(bankDetails?.accountNumber)}
              >
                <i className="ri-file-copy-fill color-mid-blue ml-2"></i>
              </span>
            </h6>
          </div>
          <div className="mb-4">
            <div className="form-label mb-2">
              <small>Bank Name</small>
            </div>
            <h6 className="page-subtitle">{bankDetails?.bankName}</h6>
          </div>
          {currency !== "NGN" ? (
            <div className="mb-4">
              <div className="form-label mb-2">
                <small>Bank Swift Code</small>
              </div>
              <h6 className="page-subtitle">{bankDetails?.swiftCode}</h6>
            </div>
          ) : null}
        </div>
      )}
      <div className="mt-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomInputField
            type="text"
            maxLength={128}
            placeholder=""
            label="Kindly upload your transaction receipt"
            errors={errors.transactionReceipt}
            hasActionButton={true}
            actionButtonText={
              topUpInfo?.base64_file_string ? "Replace" : "Choose"
            }
            onClickActionButton={() => {
              fileInputRef?.current?.click();
            }}
            {...register("transactionReceipt")}
          />
          <input
            type="file"
            className="d-none"
            ref={fileInputRef}
            onChange={onChangeReceipt}
            accept="image/png, image/jpg, image/jpeg, application/pdf"
          />
          <div className="d-flex justify-content-between mt-5 pt-5">
            <button type="submit" className="btn btn-sm advancly-btn btn-block">
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BankTransferModal;
