import React, { Fragment, useEffect, useState } from "react";
import { useActions } from "../../../hooks/redux-hooks/useActions";
import { TWithdrawalTypes } from "../../../interfaces/withdrawal";
import styles from "./WithdrawalModalStepTwo.module.scss";
import { useTypedSelector } from "./../../../hooks/redux-hooks/useTypedSelector";
import {
  selectWithdrawal,
  selectWithdrawalCurrency,
} from "./../../../redux/selectors/withdrawalSelector";
import useFunder from "./../../../hooks/custom-hooks/useFunder";
import Loader from "../../Loader/Loader.component";
import { errorHandler } from "../../../helpers/errorHandler";
import { getData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { message } from "antd";
import { IBankAccount } from "../../../interfaces/bankAccounts";
import useBanks from "../../../hooks/custom-hooks/useBanks";
import { appInsights } from "../../AppInsight/AppInsight";
import { useHistory } from "react-router-dom";

interface IWithdrawModalStepTwo {
  closeModal: () => void;
}

export const WithdrawalModalStepTwo: React.FC<IWithdrawModalStepTwo> = ({
  closeModal,
}) => {
  const history = useHistory();
  const currency = useTypedSelector(selectWithdrawalCurrency);
  const [type, setType] = useState<TWithdrawalTypes>("");
  const [fetching, setFetching] = useState(false);
  const [bankAccount, setBankAccount] = useState<IBankAccount | undefined>();
  const { setWithdrawalInfo } = useActions();
  const withdrawalInfo = useTypedSelector(selectWithdrawal);
  const [noAccount, setNoAccount] = useState(false);

  const { data, isLoading, isError, error, refetch, isFetching } = useFunder();

  const {
    data: bankLists,
    isError: bankListError,
    isLoading: bankListLoading,
  } = useBanks(currency === "NGN" ? "NG" : currency === "KES" ? "KE" : "US");

  useEffect(() => {
    if (isError || bankListError) {
      message.error("Something went wrong");
    }
  }, [isError, bankListError]);

  const handleFetchBankAccounts = async () => {
    setFetching(true);
    try {
      const response = await getData(
        `${apiEndpoints.CUSTOMER_BANK_ACCOUNTS}/${data?.funder_id}/bank_accounts`
      );
      const { bank_accounts } = response.data;
      const acct = bank_accounts.find(
        (b: { currency: any }) => b.currency === currency
      );
      if (acct) {
        setBankAccount(acct);
      } else {
        setNoAccount(true);
      }
    } catch (error) {
      message.error(errorHandler(error));
      appInsights.trackException({
        exception: new Error(error),
        properties: {
          fileName: "WithdrawalModalStepTwo.component.tsx",
        },
      });
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (data && bankLists) {
      handleFetchBankAccounts();
    }
  }, [data, bankLists]);
  return (
    <Fragment>
      <div className="modal-header border-bottom-0 p-0 mb-4">
        <h5 className="page-subtitle">
          Where would you like to withdraw into?
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Fragment>
        {isLoading || fetching || bankListLoading ? (
          <div className="py-5 d-flex justify-content-center align-items-center flex-column">
            <Loader type="lg" variant="blue" />
          </div>
        ) : error ? (
          <div className="py-5 d-flex justify-content-center align-items-center flex-column">
            <h6 className="color-red font-weight-bold text-center">
              {errorHandler(error) ||
                "An error occured while getting your account information, please try again."}
            </h6>

            <button
              type="button"
              className="btn btn-sm advancly-white-btn  mt-3"
              onClick={() => refetch()}
              disabled={isFetching}
            >
              Retry {isFetching && <Loader />}
            </button>
          </div>
        ) : noAccount ? (
          <>
            <p className="alert alert-danger">
              Add a bank account to proceed with withdrawal
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-sm advancly-white-btn w-50 mr-2"
                onClick={() =>
                  (window.location.href = "/funder/profile-settings")
                }
              >
                Add Account
              </button>
            </div>
          </>
        ) : (
          <Fragment>
            <div
              className={`${styles.modalCard}  ${
                type === "saved" && styles.active
              }`}
              onClick={() => setType("saved")}
            >
              <div className={styles.radioButton}></div>
              <div>
                <h6 className="font-weight-bold">Saved Account</h6>
                <p>
                  {bankAccount?.bank_name} {bankAccount?.account_number}
                </p>
              </div>
            </div>

            <div className="d-flex justify-content-between mt-5">
              <button
                type="button"
                className="btn btn-sm advancly-white-btn w-50 mr-2"
                onClick={() => {
                  setWithdrawalInfo({
                    ...withdrawalInfo,
                    currentStep: 1,
                  });
                }}
              >
                Back
              </button>
              <button
                type="submit"
                className="btn btn-sm advancly-btn w-50 ml-2"
                disabled={!type || isError}
                onClick={() => {
                  if (type === "saved" && bankAccount) {
                    setWithdrawalInfo({
                      ...withdrawalInfo,
                      currentStep: 3,
                      accountDetails: {
                        ...withdrawalInfo.accountDetails,
                        type,
                        accountNumber: bankAccount.account_number,
                        accountName: bankAccount?.account_name,
                      },
                    });
                  }
                }}
              >
                Continue
              </button>
            </div>
          </Fragment>
        )}
      </Fragment>
    </Fragment>
  );
};
