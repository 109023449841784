import { useState, Dispatch, SetStateAction, useEffect } from "react";
import { postData } from "../../apis/apiMethods";
import { errorHandler } from "../../helpers/errorHandler";
import { apiEndpoints } from "../../apis/apiEndpoints";
import Loader from "../Loader/Loader.component";
import { reValidateUserActivationStatus } from "../../helpers/isUserActivated";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { selectUser } from "../../redux/selectors/userSelector";
import { useActions } from "../../hooks/redux-hooks/useActions";
import { useQueryClient } from "react-query";
import { appInsights } from "../AppInsight/AppInsight";
import { Button, Divider, Form, Input, Modal, Select } from "antd";
import { IBankAccount } from "../../interfaces/bankAccounts";
import useBanks from "../../hooks/custom-hooks/useBanks";

interface IProps {
  setError: Dispatch<SetStateAction<string>>;
  setSuccess: Dispatch<SetStateAction<string>>;
  currency: string;
  handleCancel: () => void;
  isModalOpen: boolean;
  bankAccount: IBankAccount | undefined;
  otp: string;
}

const OtherBankAccountUpdate: React.FC<IProps> = ({
  setSuccess,
  setError,
  currency,
  isModalOpen,
  handleCancel,
  bankAccount,
  otp,
}) => {
  const user = useTypedSelector(selectUser);
  const [form] = Form.useForm();
  const { setCurrentUser } = useActions();
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();
  const { data, error: bankError, isLoading } = useBanks("KE");

  const handleReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (isModalOpen && bankAccount) {
      form.setFieldsValue({
        bank_account_number: bankAccount.account_number,
        bank_account_name: bankAccount.account_name,
        bank_name: bankAccount.bank_name,
        routing_number: bankAccount.routing_number,
        swift_code: bankAccount.swift_code,
      });
    } else {
      form.resetFields(); // Reset the form if bankAccount becomes undefined or null
    }
  }, [bankAccount, isModalOpen]);

  const onSubmit = async ({
    bank_account_number,
    bank_account_name,
    bank_name,
    routing_number,
    swift_code,
  }: {
    bank_account_number: string;
    bank_account_name: "string";
    bank_name: "string";
    routing_number: "string";
    swift_code: "string";
  }) => {
    setLoading(true);
    setError("");
    setSuccess("");

    const reqBody = {
      otp: otp,
      bank_name: bank_name,
      account_number: bank_account_number,
      account_name: bank_account_name,
      currency,
      funder_id: user?.funder_id,
      swift_code: swift_code,
      routing_number: routing_number,
      is_update: !!bankAccount,
      bank_code:
        currency === "USD"
          ? null
          : data?.data.find((b: { name: string }) => b.name === bank_name)
              .code || "",
    };

    try {
      await postData(
        `${apiEndpoints.CUSTOMER_BANK_ACCOUNTS}/${user?.funder_id}/bank_accounts`,
        reqBody
      );
      queryClient.invalidateQueries("getFunder");
      // Revalidate User Status if he is initally not activated
      if (!user.activated) {
        const activated = await reValidateUserActivationStatus();
        setCurrentUser({ ...user, activated });
      }

      handleReset();
      setSuccess("Bank information updated successfully.");
      setLoading(false);
      handleCancel();
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: OtherBankAccountUpdate },
      });
    }
  };
  return (
    <Modal
      title={`Add your ${currency} bank information`}
      open={isModalOpen}
      onCancel={() => {
        handleReset();
        handleCancel();
      }}
      footer={null}
    >
      <Divider />
      <Form onFinish={onSubmit} layout="vertical" size="large" form={form}>
        {currency === "USD" ? (
          <Form.Item
            name="bank_name"
            label="Bank Name"
            rules={[{ required: true, message: "Please enter bank name" }]}
          >
            <Input type="text" maxLength={128} placeholder="Bank Name" />
          </Form.Item>
        ) : (
          <Form.Item
            name="bank_name"
            label="Bank"
            rules={[{ required: true, message: "Please select your bank!" }]}
          >
            <Select
              placeholder="Choose Bank"
              onChange={() => {
                form.setFieldValue("bank_account_number", "");
              }}
            >
              {data?.data?.map(({ name, id }: { name: string; id: number }) => (
                <Select.Option value={name} key={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="bank_account_number"
          label="Account Number"
          rules={[{ required: true, message: "Please enter account number" }]}
        >
          <Input
            type="number"
            maxLength={20}
            placeholder="Enter Account number"
          />
        </Form.Item>

        <Form.Item
          name="bank_account_name"
          label="Account Name"
          rules={[{ required: true, message: "Please enter account name" }]}
        >
          <Input type="text" maxLength={128} placeholder="Account Name" />
        </Form.Item>

        <Form.Item name="routing_number" label="Routing Number">
          <Input type="text" maxLength={9} placeholder="Routing Number" />
        </Form.Item>

        <Form.Item name="swift_code" label="Swift Code">
          <Input type="text" maxLength={11} placeholder="Swift Code" />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          className="advancly-btn mt-3 w-100"
          disabled={loading}
        >
          Update Bank Information {loading && <Loader />}
        </Button>
      </Form>
    </Modal>
  );
};
export default OtherBankAccountUpdate;
